import React, { createContext, FC } from 'react'

interface PageContext {
  fullSlug: string
  children?: React.ReactNode
}

export const PageContext = createContext<PageContext>({
  fullSlug: '',
})

export const PageProvider: FC<PageContext> = ({ fullSlug, children }) => {
  const value = { fullSlug }

  return React.createElement(PageContext.Provider, { value }, children)
}
