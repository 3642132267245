let locales: { [key: string]: string } = {}

locales[(locales['en-GB'] = 'gb')] = 'en-GB'
locales[(locales['de-DE'] = 'de')] = 'de-DE'
locales[(locales['sv-SE'] = 'se')] = 'sv-SE'
locales[(locales['sv-FI'] = 'sv-fi')] = 'sv-FI'
locales[(locales['es-ES'] = 'es')] = 'es-ES'
locales[(locales['es-MX'] = 'mx')] = 'es-MX'
locales[(locales['pt-BR'] = 'br')] = 'pt-BR'
locales[(locales['nb-NO'] = 'no')] = 'nb-NO'
locales[(locales['it-IT'] = 'it')] = 'it-IT'
locales[(locales['fr-FR'] = 'fr')] = 'fr-FR'
locales[(locales['fi-FI'] = 'fi')] = 'fi-FI'
locales[(locales['nl-NL'] = 'nl')] = 'nl-NL'
locales[(locales['da-DK'] = 'dk')] = 'da-DK'

export const countryToLang = (code: string) => {
  try {
    return locales[code].split('-')[0]
  } catch {
    return 'en'
  }
}
export const getCountryCodeFromSlug = (slug: string) => slug.split('/')[0]
export const getLocaleCodeFromCountryCode = (code: string) => locales[code]
export const isValidCountyOrLocale = (code: string) => Boolean(locales[code])
