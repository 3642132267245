import React, { FC, useEffect } from 'react'
import Head from 'next/head'

import { countryToLang } from '../utils/i18n-utils'

const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://ga.zettle.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MD45XHL');`

const PUSH_EVENT = 'wn.push'

interface TrackingEvent {
  event: string
  name: string
  country: string
  lang: string
}

const updateDataLayer = (dataLayer: any[], data: TrackingEvent) => {
  dataLayer.forEach((element, index) => {
    if (element.event === PUSH_EVENT) {
      dataLayer.splice(index, 1)
    }
  })
  dataLayer.push(data)
}

export const Tracking: FC<{ countryCode: string; name: string }> = ({
  countryCode,
  name,
}) => {
  useEffect(() => {
    if (window?.storyblok?.isInEditor()) {
      return
    }

    const data = {
      event: PUSH_EVENT,
      country: countryCode,
      lang: countryToLang(countryCode),
      name,
    }

    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      updateDataLayer(window.dataLayer, data)
    }
  }, [countryCode, name])

  return (
    <>
      <Head>
        <script dangerouslySetInnerHTML={{ __html: GTM_SCRIPT }} />
      </Head>
      <noscript>
        <iframe
          title="GTM"
          src="https://ga.zettle.com/ns.html?id=GTM-MD45XHL"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}
