import React, { FC } from 'react'
import Head from 'next/head'

export const FavIcon: FC = () => (
  <Head>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="https://cdn.izettle.com/favicons/apple-touch-icon-v2-blue.png"
    />
    <link
      rel="icon"
      type="image/png"
      href="https://cdn.izettle.com/favicons/favicon-v2-32x32-blue.png"
      sizes="32x32"
    />
    <link
      rel="icon"
      type="image/png"
      href="https://cdn.izettle.com/favicons/favicon-v2-16x16-blue.png"
      sizes="16x16"
    />
    <link
      rel="manifest"
      href="https://cdn.izettle.com/favicons/manifest-v2-blue.json"
    />
    <link
      rel="shortcut icon"
      href="https://cdn.izettle.com/favicons/favicon-v2-blue.ico"
    />
    <link
      rel="mask-icon"
      href="https://cdn.izettle.com/favicons/safari-pinned-tab-blue.svg"
      color="#001c64"
    />
  </Head>
)
