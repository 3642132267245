import React from 'react'
import * as Sentry from '@sentry/browser'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}
export class ErrorBoundary extends React.Component<
  any,
  { error: Error | null }
> {
  state = { error: null }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    if (process.env.enableSentry) {
      Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
      })
      Sentry.captureException(error)
    }
  }

  render() {
    if (this.state.error) {
      return <h1>Something went wrong!</h1>
    }

    return this.props.children
  }
}
