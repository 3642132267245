export const createPriceUtils = (pricing: any) => {
  return {
    getPrice: (countryCode: string, product: string) => {
      try {
        return pricing[countryCode][product]
      } catch {
        return ''
      }
    },
  }
}
