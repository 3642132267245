import React, { FC } from 'react'
import Head from 'next/head'

const ANTIFLICKER_SCRIPT = `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
})(window,document.documentElement,'async-hide','dataLayer',4000,
{'GTM-5SX6G6B':true});`

const ANTIFLICKER_STYLES = `.async-hide { opacity: 0 !important}`

export const Optimize: FC = () => {
  return (
    <Head>
      <style>{ANTIFLICKER_STYLES}</style>
      <script dangerouslySetInnerHTML={{ __html: ANTIFLICKER_SCRIPT }} />
      <script src="https://www.googleoptimize.com/optimize.js?id=GTM-5SX6G6B" />
    </Head>
  )
}
