import React, { useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { StoryblokComponent } from '@storyblok/react'

import { HeaderContext } from '@izettle/public-site-components/dist/web-next'
import { LayoutProps } from './types'

export const Layout = (props: LayoutProps) => {
  const router = useRouter()
  const { closeSideAndMobileMenu } = useContext(HeaderContext)
  const { header, banner, footer, children, locationData } = props

  useEffect(() => {
    router.events.on('routeChangeStart', closeSideAndMobileMenu)
    return () => {
      router.events.off('routeChangeStart', closeSideAndMobileMenu)
    }
  }, [])

  return (
    <>
      {header?.map((content) => (
        <StoryblokComponent blok={content} {...content} key={content._uid} />
      ))}

      <main role="main" id="main" className="main public-web-wrapper">
        {banner?.map((content) => (
          <StoryblokComponent blok={content} {...content} key={content._uid} />
        ))}
        {children}
      </main>

      {footer?.map((content) => (
        <StoryblokComponent
          blok={{ ...content, locationData }}
          {...content}
          locationData={locationData}
          key={content._uid}
        />
      ))}
    </>
  )
}
