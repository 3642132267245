import React, { FC } from 'react'
import Head from 'next/head'

import { SeoPlugin } from '../typings'
import { HrefLangAlternate } from '../services/href-lang'
import { getLinkFromHrefLang } from '../utils/hreflang-utils'
import { getCanonicalUrl } from '../utils/canonical-utils'

const trimTrailingSlash = (s: string) => s.replace(/\/$/, '')
interface SeoProps {
  fullSlug: string
  alternates?: HrefLangAlternate[]
  seo: SeoPlugin
  statusCode: number
}

export const Seo: FC<SeoProps> = ({
  fullSlug,
  alternates = [],
  seo,
  statusCode,
}) => {
  const { title, robots, description, canonical } = seo
  const canonicalUrl = getCanonicalUrl({ canonical, fullSlug, statusCode })

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robots} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {alternates.map((item, i) => (
        <link
          key={i}
          rel="alternate"
          hrefLang={item.locale}
          href={getLinkFromHrefLang(item.url)}
        />
      ))}
    </Head>
  )
}
