import statusCodes from 'http-status-codes'

const INTERNATIONAL_PAGE_URL = 'international'
const ROOT_URL = '/'

const trimTrailingSlash = (s: string) => s.replace(/\/$/, '')
const isInternationalPage = (url: string) =>
  trimTrailingSlash(url) === INTERNATIONAL_PAGE_URL

export const getCanonicalUrl = ({
  canonical,
  fullSlug,
  statusCode,
}: {
  canonical: string
  fullSlug: string
  statusCode: number
}): string | undefined => {
  let url
  if (statusCode === statusCodes.NOT_FOUND) {
    return undefined
  }

  if (isInternationalPage(fullSlug)) {
    return `${process.env.baseUrl}${ROOT_URL}`
  }

  if (canonical.length > 0) {
    url = trimTrailingSlash(canonical)
  }

  if (canonical.length === 0) {
    url = trimTrailingSlash(fullSlug)
  }

  return `${process.env.baseUrl}/${url}`
}
