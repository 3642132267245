import { FC } from 'react'

import { Seo } from '../components/seo'
import { Layout } from '../content-types/layout'
import { Page } from '../content-types/page'
import { PageProps } from '../typings/next-page'

export const NotFoundPage: FC<PageProps> = ({ page, layout, statusCode }) => {
  if (!page) {
    return null
  }

  const pageContent = page?.data?.story?.content || {}
  const layoutContent = layout?.data?.story?.content || {}
  const locationData = {
    alternates: page.data.story.alternates,
    fullSlug: page.data.story.full_slug,
  }

  return (
    <>
      {pageContent.seo && (
        <Seo
          fullSlug={page.data.story.full_slug}
          seo={pageContent.seo}
          statusCode={statusCode}
        />
      )}
      <Layout {...layoutContent} locationData={locationData}>
        <Page {...pageContent} />
      </Layout>
    </>
  )
}
