import React, { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  HeaderContextProvider,
  Provider as SiteComponentsProvider,
} from '@izettle/public-site-components/dist/web-next'
import { ErrorBoundary } from '../components/error-boundary'
import { Tracking } from '../components/tracking'
import { Optimize } from '../components/optimize'
import { FavIcon } from '../components/favicon'
import {
  getCountryCodeFromSlug,
  getLocaleCodeFromCountryCode,
} from '../utils/i18n-utils'
import { createPriceUtils } from '../utils/price-utils'
import { PageProvider } from '../contexts/page-context'
import { NotFoundPage } from '../page-components/not-found-page'

import '@izettle/public-site-components/dist/global.css'
import '@izettle/public-site-components/dist/web-next.css'

const CustomApp = (props: any) => {
  const { Component, pageProps } = props
  const name = pageProps?.page?.data?.story?.name || ''
  const addOptimizeScripts =
    pageProps?.page?.data?.story?.content?.addOptimizeScripts || false
  const fullSlug = pageProps?.page?.data?.story?.full_slug || 'gb'
  const pricing = pageProps?.pricing
  const countryCode = getCountryCodeFromSlug(fullSlug)
  const localeCode = getLocaleCodeFromCountryCode(countryCode)

  useEffect(() => {
    document && (document.documentElement.lang = localeCode || 'en')
  }, [localeCode])

  return (
    <ErrorBoundary>
      <PageProvider fullSlug={fullSlug}>
        <FavIcon />
        {addOptimizeScripts && <Optimize />}
        <Tracking name={name} countryCode={countryCode} />
        <SiteComponentsProvider
          provider="next"
          components={{
            Link: (props: any) => <Link prefetch={false} {...props} />,
          }}
          hooks={{ useRouter }}
          localeCode={localeCode}
          pricing={createPriceUtils(pricing)}
        >
          <HeaderContextProvider>
            {pageProps?.error?.statusCode === 404 ? (
              <NotFoundPage {...pageProps} />
            ) : (
              <Component {...pageProps} />
            )}
          </HeaderContextProvider>
        </SiteComponentsProvider>
      </PageProvider>
    </ErrorBoundary>
  )
}

export default CustomApp
