import React, { FC, useContext } from 'react'
import { StoryblokComponent } from '@storyblok/react'

import { SeoPlugin } from '../typings'
import { PageContext } from '../contexts/page-context'

export interface PageProps {
  _uid: string
  _editable: string
  body: any
  layout: string
  component: string
  seo: SeoPlugin
  addOptimizeScripts?: boolean
}

const urlForwardTargets = [
  'hero',
  'product-offer-structured-data',
  'company-info-structured-data',
]

export const revalidate = 60 * 15 // revalidate this page every 15 minutes

export const Page: FC<PageProps> = ({ body }) => {
  const { fullSlug } = useContext(PageContext)
  const baseUrl = process.env.baseUrl

  return (
    <>
      {body?.map?.((content: any) => {
        if (urlForwardTargets.includes(content.component)) {
          return (
            <StoryblokComponent
              blok={{ ...content, fullSlug, baseUrl }}
              {...{ ...content, fullSlug, baseUrl }}
              key={content._uid}
            />
          )
        }
        return (
          <StoryblokComponent blok={content} {...content} key={content._uid} />
        )
      })}
    </>
  )
}
